import axios from '@axios'

export default function FeedServices() {
  const getFeeds = async (queryParams) => {
    const api = await axios.get('/feed', queryParams)

    return api
  }

  const createFeed = async (queryParams) => {
    const api = await axios.post('/feed/create' , queryParams)

    return api
  }

  const editFeed = async (queryParams) => {
    const api = await axios.post('/feed/update' , queryParams)

    return api
  }

  const fetchUsers = async (queryParams) => {
    const res = await axios.get('/users', { params: queryParams })

    return res
  }

  return {
    getFeeds,
    createFeed,
    fetchUsers,
    editFeed
  }
}