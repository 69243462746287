<template>
    <b-sidebar id="add-new-feed-sidebar" :visible="isAddNewFeedSidebarActive" bg-variant="white" sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm">
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ itemsFeed[0].nameSidebar }} postagem
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
        <!-- BODY -->
        <validation-observer
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2 pt-5"
            @submit.prevent="submit()"
            @reset.prevent="resetForm"
            enctype="multipart/form-data"
          >
            <!-- Title -->
            <validation-provider
              v-slot="{ errors }"
              name="title"
              rules="required"
            >
              <b-form-group
                label="Titulo"
                label-for="title"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input
                  id="title"
                  v-model="form.title"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Secretary -->
            <b-form-group
                label="Secretaria"
                label-for="secretary"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="secretary"
                  vid="secretary"
                  rules="required"
                >
                  <b-form-select
                    id="secretary"
                    v-model="form.secretary"
                    name="secretary"
                    :state="errors.length > 0 ? false : null"
                    :options="secretaryOptions"
                    placeholder="Selecione a secretaria"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Banner -->
              <validation-provider
                v-slot="{ errors }"
                name="banner"
                rules="required"
              >
                <b-form-group
                  label="Banner"
                  label-for="banner"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-file
                    id="banner"
                    v-model="form.banner"
                    placeholder="Escolha um banner"
                    drop-placeholder="Arraste seu arquivo para aqui..."
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <!-- Link -->
              <validation-provider
                v-slot="{ errors }"
                name="link"
                rules="required"
              >
                <b-form-group
                  label="Link"
                  label-for="link"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="link"
                    v-model="form.link"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              
              <!-- Form Actions -->
              <div class="d-flex justify-content-end mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mr-2"
                  type="submit"
                  :disabled="isBusy"
                >
                  <b-spinner v-if="isBusy" />
                  <template v-else>
                    Salvar
                  </template>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  @click="hide"
                >
                  Cancelar
                </b-button>
              </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </template>
  
<script>
import FeedServices from './FeedService'
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormFile, VBTooltip
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required
} from 'vee-validate/dist/rules'
import Ripple from 'vue-ripple-directive'

extend('required', {
  ...required,
  message: 'Esse campo é obrigatório.',
})

export default {
  data() {
    return {
      isBusy: false,
      form: {
        id: null,
        title: null,
        secretary: null,
        banner: null,
        link: null,
        user_id: null
      },
      secretaryOptions: [
        { value: '', text: 'Selecione a secretaria', disabled: true },
        { value: 'educacao', text: 'Secretaria de Educação' },
        { value: 'saude', text: 'Secretaria de Saúde' },
        { value: 'transporte', text: 'Secretaria de Transporte' },
        { value: 'cultura', text: 'Secretaria de Cultura' },
        { value: 'meio_ambiente', text: 'Secretaria de Meio Ambiente' },
        { value: 'obras_publicas', text: 'Secretaria de Obras Públicas' },
        { value: 'assistencia_social', text: 'Secretaria de Assistência Social' },
        { value: 'financas', text: 'Secretaria de Finanças' },
        { value: 'esporte_lazer', text: 'Secretaria de Esporte e Lazer' },
        { value: 'desenvolvimento_economico', text: 'Secretaria de Desenvolvimento Econômico' },
        { value: 'turismo', text: 'Secretaria de Turismo' },
        { value: 'agricultura', text: 'Secretaria de Agricultura' },
        { value: 'planejamento', text: 'Secretaria de Planejamento' },
        { value: 'comunicacao', text: 'Secretaria de Comunicação' },
        { value: 'seguranca_publica', text: 'Secretaria de Segurança Pública' },
        { value: 'habitacao', text: 'Secretaria de Habitação' },
        { value: 'recursos_humanos', text: 'Secretaria de Recursos Humanos' },
        { value: 'tecnologia_informacao', text: 'Secretaria de Tecnologia da Informação' },
        { value: 'industria_comercio', text: 'Secretaria de Indústria e Comércio' },
      ]
    }
  },
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isAddNewFeedSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemsFeed: {
      type: Array
    }
  },
  mounted() {
    this.form.user_id = JSON.parse(window.localStorage.getItem('userData')).id;

    this.form.id = this.itemsFeed[0].id;
    this.form.title = this.itemsFeed[0].title;
    this.form.secretary = this.itemsFeed[0].secretary;
    this.form.banner = this.itemsFeed[0].banner;
    this.form.link = this.itemsFeed[0].link;
  },
  methods: {
    resetForm() {
      this.form = {
        title: null,
        order: null
      }
      this.$emit('close')
    },
    submit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          try {
            this.isBusy = true
            if(this.itemsFeed[0].nameSidebar === 'Adicionar') {
              const formData = new FormData();
              formData.append('title', this.form.title);
              formData.append('secretary', this.form.secretary);
              formData.append('banner', this.form.banner);
              formData.append('link', this.form.link);
              formData.append('user_id', this.form.user_id);

              FeedServices().createFeed(formData).then((response) => {
                if (response.status === 400) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      icon: 'InfoIcon',
                      title: response.message,
                      text: ` ERROR: ${response.errors[0][0]} #${response.errors[0][1]}`,
                      variant: 'danger',
                    }
                  })
                } else if (response.status === 200) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Post criado com sucesso!',
                      icon: 'InfoIcon',
                      variant: 'success',
                    }
                  })
                  this.$emit('close')
                  this.$emit('onSaved', true)
                }
              }).catch((err) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    icon: 'InfoIcon',
                    title: err.message,
                    variant: 'danger',
                  }
                })
              })
                .finally(() => { this.isBusy = false })
            } else {
              const formData = new FormData();
              formData.append('id', this.form.id);
              formData.append('title', this.form.title);
              formData.append('secretary', this.form.secretary);
              formData.append('banner', this.form.banner);
              formData.append('link', this.form.link);

              FeedServices().editFeed(formData).then((response) => {
                if (response.status === 400) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      icon: 'InfoIcon',
                      title: response.message,
                      text: ` ERROR: ${response.errors[0][0]} #${response.errors[0][1]}`,
                      variant: 'danger',
                    }
                  })
                } else if (response.status === 200) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.message,
                      icon: 'InfoIcon',
                      variant: 'success',
                    }
                  })
                  this.$emit('close')
                  this.$emit('onSaved', true)
                }
              }).catch((err) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    icon: 'InfoIcon',
                    title: err.message,
                    variant: 'danger',
                  }
                })
              })
                .finally(() => { this.isBusy = false })
            }
          } catch (err) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'InfoIcon',
                title: err.message,
                variant: 'danger',
              }
            })
          }
        }
      })
    },
  }
}

</script>

<style>

</style>